import React from "react";
import {useNavigate} from "react-router-dom";
import {Button, Container, Typography} from "@mui/material";
import {requestForToken} from "../pushNotifications";

const PushNotificationsSetup = () => {
  const navigate = useNavigate();

  const handleEnable = async () => {
    const token = await requestForToken();
    if (token) {
      localStorage.setItem("pushNotificationsEnabled", "true");
      alert("Push notifications enabled successfully!");
    } else {
      alert("Failed to enable push notifications.");
    }
    // Proceed to next onboarding step
    navigate("/onboarding-check-elderly");
  };

  const handleSkip = () => {
    localStorage.setItem("pushNotificationsEnabled", "false");
    navigate("/onboarding-check-elderly");
  };

  return (
    <Container maxWidth="sm" style={{ textAlign: "center", padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Enable Push Notifications?
      </Typography>
      <Typography variant="body1" gutterBottom>
        Enabling push notifications will allow you to receive real-time updates such as app updates, heart notifications, and contact changes. This ensures you are always informed about your client’s status and can react promptly.
      </Typography>
      <Typography variant="body1" gutterBottom>
        We recommend enabling notifications to keep your app experience smooth and current.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleEnable} style={{ margin: "10px" }}>
        Enable Notifications
      </Button>
      <Button variant="outlined" color="secondary" onClick={handleSkip} style={{ margin: "10px" }}>
        Skip
      </Button>
    </Container>
  );
};

export default PushNotificationsSetup;
