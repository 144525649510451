import React from "react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import "./ElderlyDashboard.scss"

const ElderlyDashboard = () => {
  const navigate = useNavigate()
  const selectedElderly = useSelector(state => state.elderly.selected)
  if (!selectedElderly) {
    navigate("/")
    return null
  }
  const menuItems = [
    { label: "Notifications", icon: "error", path: null },
    { label: "Add Contact", icon: "circle", path: "/contacts" },
    { label: "Contact Order", icon: "circle", path: "/favorites" },
    { label: "App Order", icon: "circle", path: null },
    { label: "Where are you?", icon: "circle", path: null },
    { label: "Settings", icon: "circle", path: "/settings" },
    { label: "Update App", icon: "update", path: "/update-app" }
  ]
  return (
    <div className="elderly-dashboard">
      <button className="back-button" onClick={() => navigate("/")}>
        ← Back
      </button>
      <h1 className="title">{selectedElderly.name}</h1>
      <h2 className="subtitle">Dashboard</h2>
      <div className="menu">
        {menuItems.map((item, index) => (
          <div key={index} className="menu-item" onClick={item.path ? () => navigate(item.path) : undefined}>
            <span className={`icon ${item.icon}`}></span>
            <span className="text">{item.label}</span>
            {item.path && <span className="arrow">{">"}</span>}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ElderlyDashboard
