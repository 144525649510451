// src/components/Settings/Settings.js
import React, { useState } from 'react';
import './Settings.scss';

const Settings = () => {
  const [settings, setSettings] = useState({
    TAP_MIN_TIME_MS: 200,
    SCREEN_LOCK_TIME_MS: 30000,
    UNREAD_MESSAGES_PERIOD_IN_EPOCH_MS: 604800000,
    UNSEEN_LOGS_PERIOD_IN_EPOCH_MS: 604800000,
    AUTO_SPEAKER_ON: false,
    SHOW_MUTE: true,
  });

  const handleInputChange = (key, value) => {
    setSettings({
      ...settings,
      [key]: value,
    });
  };

  const handleCheckboxChange = (key) => {
    setSettings({
      ...settings,
      [key]: !settings[key],
    });
  };

  const handleSave = () => {
    console.log("Settings saved:", settings);
    // Optionally save settings to a server or local storage
  };

  return (
    <div className="settings-container">
      <h2>Settings</h2>
      <div className="setting-item">
        <label htmlFor="tap-min-time">TAP_MIN_TIME_MS:</label>
        <input
          type="number"
          id="tap-min-time"
          value={settings.TAP_MIN_TIME_MS}
          onChange={(e) => handleInputChange('TAP_MIN_TIME_MS', Number(e.target.value))}
        />
      </div>
      <div className="setting-item">
        <label htmlFor="screen-lock-time">SCREEN_LOCK_TIME_MS:</label>
        <input
          type="number"
          id="screen-lock-time"
          value={settings.SCREEN_LOCK_TIME_MS}
          onChange={(e) => handleInputChange('SCREEN_LOCK_TIME_MS', Number(e.target.value))}
        />
      </div>
      <div className="setting-item">
        <label htmlFor="unread-messages-period">UNREAD_MESSAGES_PERIOD_IN_EPOCH_MS:</label>
        <input
          type="number"
          id="unread-messages-period"
          value={settings.UNREAD_MESSAGES_PERIOD_IN_EPOCH_MS}
          onChange={(e) => handleInputChange('UNREAD_MESSAGES_PERIOD_IN_EPOCH_MS', Number(e.target.value))}
        />
      </div>
      <div className="setting-item">
        <label htmlFor="unseen-logs-period">UNSEEN_LOGS_PERIOD_IN_EPOCH_MS:</label>
        <input
          type="number"
          id="unseen-logs-period"
          value={settings.UNSEEN_LOGS_PERIOD_IN_EPOCH_MS}
          onChange={(e) => handleInputChange('UNSEEN_LOGS_PERIOD_IN_EPOCH_MS', Number(e.target.value))}
        />
      </div>
      <div className="setting-item">
        <label htmlFor="auto-speaker-on">AUTO_SPEAKER_ON:</label>
        <input
          type="checkbox"
          id="auto-speaker-on"
          checked={settings.AUTO_SPEAKER_ON}
          onChange={() => handleCheckboxChange('AUTO_SPEAKER_ON')}
        />
      </div>
      <div className="setting-item">
        <label htmlFor="show-mute">SHOW_MUTE:</label>
        <input
          type="checkbox"
          id="show-mute"
          checked={settings.SHOW_MUTE}
          onChange={() => handleCheckboxChange('SHOW_MUTE')}
        />
      </div>
      <button className="save-settings-button" onClick={handleSave}>
        Save Settings
      </button>
    </div>
  );
};

export default Settings;
