import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import { getMessaging, isSupported } from "firebase/messaging";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const initializeMessaging = async () => {
  const supported = await isSupported();
  if (supported) {
    console.log("Messaging is supported");
    return getMessaging(app);
  } else {
    console.log("Messaging is not supported in this browser");
    return null;
  }
};


export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const messaging = initializeMessaging();
