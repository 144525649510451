import { configureStore } from "@reduxjs/toolkit"
import elderlyReducer from "./elderlySlice"

const store = configureStore({
  reducer: {
    elderly: elderlyReducer
  }
})

export default store
