import { getToken } from "firebase/messaging";
import axios from "axios";
import {messaging} from "./firebase";

export const requestForToken = async () => {
  try {

      let currentToken = localStorage.getItem("fcmToken");
      if (!currentToken) {
        messaging ? console.log("messaging is defined") : console.log("messaging is NOT defined");
        if ('serviceWorker' in navigator) {
          const registration = await navigator.serviceWorker.getRegistration('/firebase-messaging-sw.js');
          currentToken = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_VAPID_KEY,
            serviceWorkerRegistration: registration,
          });
          console.log("currentToken", currentToken);
          const accessToken = localStorage.getItem("accessToken");
          try {
            await axios.put(
              `${process.env.REACT_APP_BACKEND_URL}/admin/fcm-token`,
              { fcmToken: currentToken },
              { headers: { Authorization: `Bearer ${accessToken}` } }
            );
            localStorage.setItem("fcmToken", currentToken);
            return currentToken;
          } catch (error) {
            console.error("Error updating fcm token:", error);
          }
        }
      }
    console.log("The token is ", currentToken);

  } catch (error) {
    console.error("An error occurred while retrieving push token.", error);
    return null;
  }
};
