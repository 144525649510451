// src/components/Onboarding/Onboarding.js
import React, { useState } from "react"
import axios from "axios"
import { Box, Button, Container, TextField, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { requestForToken } from "../../pushNotifications"
import { useDispatch } from "react-redux"
import { setSelectedElderly } from "../../store/elderlySlice"

function Onboarding() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [elderlyDisplayName, setElderlyDisplayName] = useState("")
  const [showNotificationsPrompt, setShowNotificationsPrompt] = useState(false)

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("accessToken")
      const pairingToken = localStorage.getItem("pairingToken")
      if (!pairingToken) {
        throw new Error("No pairing token found in localStorage.")
      }
      const base64Url = pairingToken.split(".")[1]
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
      const payload = JSON.parse(window.atob(base64))
      const elderlyId = payload.userId
      dispatch(setSelectedElderly({ id: elderlyId, name: elderlyDisplayName }))
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/admin/elderly`,
        { elderlyId, name: elderlyDisplayName },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      if (Notification.permission !== "granted") {
        setShowNotificationsPrompt(true)
      } else {
        navigate("/onboarding-check-elderly")
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleEnableNotifications = async () => {
    await requestForToken()
    navigate("/onboarding-check-elderly")
  }

  const handleSkipNotifications = () => {
    localStorage.setItem("pushNotificationsEnabled", "false")
    navigate("/onboarding-check-elderly")
  }

  return (
    <Container
      maxWidth="sm"
      sx={{
        mt: 5,
        background: "linear-gradient(135deg, #a1c4fd 0%, #c2e9fb 100%)",
        padding: 3,
        borderRadius: "16px",
        boxShadow: 3
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" gap={2}>
        <Typography variant="h4" gutterBottom>
          Onboarding
        </Typography>
        <Typography variant="body1" color="text.secondary">
          You are about to manage your elderly’s phone. Please give a descriptive name so you can easily identify or refer to it in the future.
        </Typography>
        <TextField
          variant="outlined"
          label="Elderly Phone Display Name"
          value={elderlyDisplayName}
          onChange={(e) => setElderlyDisplayName(e.target.value)}
          fullWidth
        />
        {!showNotificationsPrompt && (
          <Button variant="contained" onClick={handleSubmit} sx={{ mt: 2 }}>
            Save & Continue
          </Button>
        )}
        {showNotificationsPrompt && (
          <>
            <Typography variant="body1" color="text.secondary">
              Would you like to enable push notifications? Enabling notifications will allow you to receive real‑time updates (such as app updates, heart notifications, and contact changes) so you can keep track of the client’s status.
            </Typography>
            <Box>
              <Button variant="contained" color="primary" onClick={handleEnableNotifications} sx={{ m: 1 }}>
                Enable Notifications
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleSkipNotifications} sx={{ m: 1 }}>
                Skip
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Container>
  )
}

export default Onboarding
