import { createSlice } from "@reduxjs/toolkit"

const initialState = { selected: null }

export const elderlySlice = createSlice({
  name: "elderly",
  initialState,
  reducers: {
    setSelectedElderly: (state, action) => {
      state.selected = action.payload
    },
    clearSelectedElderly: (state) => {
      state.selected = null
    }
  }
})

export const { setSelectedElderly, clearSelectedElderly } = elderlySlice.actions
export default elderlySlice.reducer
