import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {auth} from '../firebase';
import {GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import {useAuthState} from 'react-firebase-hooks/auth';
import {Alert, Box, Button, Card, CardContent, Container, Typography} from '@mui/material';
import {Google} from '@mui/icons-material';
import axios from 'axios';
function SignIn() {
  const navigate = useNavigate();
  const {ptoken} = useParams();
  const [user, loading] = useAuthState(auth);
  const [elderlyUserId, setElderlyUserId] = React.useState('');
  React.useEffect(() => {
    if (ptoken) {
      try {
        const base64Url = ptoken.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const payload = JSON.parse(window.atob(base64));
        if (payload.userId) {
          setElderlyUserId(payload.userId);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [ptoken]);
  React.useEffect(() => {
    if (loading) return;
    if (!ptoken && user) {
      navigate('/admin');
    }
  }, [ptoken, user, loading, navigate]);
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    if (ptoken) {
      provider.setCustomParameters({prompt: 'select_account'});
    }
    try {
      const result = await signInWithPopup(auth, provider);
      const loggedInUser = result.user;
      const token = await loggedInUser.getIdToken();
      localStorage.setItem('accessToken', token);
      if (ptoken) {
        localStorage.setItem('pairingToken', ptoken);
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/admin`,
          {pairingToken: ptoken},
          {headers: {Authorization: `Bearer ${token}`}}
        );
        navigate('/onboarding');
      } else {
        navigate('/admin');
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Container maxWidth="sm" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: '100vh', background: 'linear-gradient(135deg, #fbc2eb 0%, #a6c1ee 100%)'}}>
      <Card sx={{width: '100%', p: 2, borderRadius: '16px', boxShadow: 3}}>
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" gap={2}>
            <Typography variant="h4" gutterBottom>
              Welcome to Thumber Phone!
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{mb: 3}}>
              Please sign in with your Google account to continue.
            </Typography>
            {elderlyUserId && (
              <Alert severity="info" sx={{mb: 2}}>
                You are about to be paired with the elderly user <strong>{elderlyUserId}</strong>. After logging in, you will be able to remotely control this user’s device.
              </Alert>
            )}
            <Button variant="contained" startIcon={<Google/>} onClick={handleGoogleSignIn} sx={{backgroundColor: '#4285F4', color: '#fff', '&:hover': {backgroundColor: '#357ae8'}}}>
              Sign in with Google
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}
export default SignIn;
