import React from "react"
import axios from "axios"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt"
import { useSelector } from "react-redux"
import "./UpdateApp.scss"

const UpdateApp = () => {
  const selectedElderly = useSelector(state => state.elderly.selected)
  if (!selectedElderly) {
    return <div>Please select an elderly from the dashboard.</div>
  }
  const { id: elderlyId, name: elderlyName } = selectedElderly
  const token = localStorage.getItem("accessToken")
  const handleStartUpdate = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/app-version`,
        { elderlyId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      toast.info("Update process initiated. Please wait...", { position: "top-right" })
      console.log("Update initiated:", response.data)
    } catch (error) {
      toast.error("Failed to initiate update operation.", { position: "top-right" })
      console.error("Error initiating update:", error)
    }
  }
  return (
    <div className="update-app">
      <div className="card">
        <SystemUpdateAltIcon className="update-icon" />
        <h1 className="title">New App Version Available!</h1>
        <p className="message">
          Dear Admin, please note that a new version of the app is now available for <strong>{elderlyName}</strong>.
          Since the elderly can be apprehensive about technology, it’s important to prepare yourself before calling them.
          When you call, kindly explain that their device will soon display an update dialog with a prominent green button at the top.
          Reassure them that, although the process might feel a bit unusual, we have taken every precaution to ensure a smooth update.
          Once you have briefed them, please click the button below to start the update operation.
        </p>
        <button className="update-button" onClick={handleStartUpdate}>
          Start Update Operation
        </button>
      </div>
    </div>
  )
}
export default UpdateApp
