// src/components/Onboarding/OnboardingCheckElderly.js
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Box, Button, Card, CardContent, CircularProgress, Container, Fade, Stack, Typography } from "@mui/material"
import { keyframes, styled } from "@mui/system"
import { CheckCircleOutline, Favorite } from "@mui/icons-material"
import axios from "axios"
import { useSelector } from "react-redux"

const ICON_ANIMATION_DURATION = 1000
const TEXT_DELAY = 300
const BUTTON_DELAY = 500
const pulse = keyframes`
    0% { transform: scale(1); }
    70% { transform: scale(1.1); }
    100% { transform: scale(1); }
`
const scaleUpDown = keyframes`
    0% { transform: scale(1); }
    50% { transform: scale(2); }
    100% { transform: scale(1); }
`
const PulsatingHeart = styled(Favorite)(() => ({
  fontSize: "5rem",
  color: "#ED64A6",
  animation: `${pulse} 1.5s infinite ease-in-out`
}))
const HeartContainer = styled("div")(({ playAnimation }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  animation: playAnimation ? `${scaleUpDown} ${ICON_ANIMATION_DURATION}ms ease-in-out` : "none"
}))

function OnboardingCheckElderly() {
  const navigate = useNavigate()
  const selectedElderly = useSelector(state => state.elderly.selected)
  const elderlyId = selectedElderly?.id
  const [phase, setPhase] = useState("LOADING")
  const [loveAnimationPlaying, setLoveAnimationPlaying] = useState(false)
  const [loveSent, setLoveSent] = useState(false)
  const [showDashboardButton, setShowDashboardButton] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setPhase("READY")
    }, 3000)
    return () => clearTimeout(timer)
  }, [])

  const handleSendLove = async () => {
    setLoveAnimationPlaying(true)
    const token = localStorage.getItem("accessToken")
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/send-emoji`,
      { elderlyId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      }
    )
    setTimeout(() => {
      setLoveAnimationPlaying(false)
      setTimeout(() => {
        setLoveSent(true)
        setTimeout(() => {
          setShowDashboardButton(true)
        }, BUTTON_DELAY)
      }, TEXT_DELAY)
    }, ICON_ANIMATION_DURATION)
  }

  const handleGoToDashboard = () => {
    navigate("/dashboard")
  }

  return (
    <Container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", minHeight: "100vh", background: "linear-gradient(135deg, #f6d365 0%, #fda085 100%)" }}>
      <Card sx={{ width: "100%", p: 2, borderRadius: "16px", boxShadow: 3 }}>
        <CardContent>
          {phase === "LOADING" && (
            <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" gap={2}>
              <Typography variant="h4" gutterBottom>
                Checking your elderly’s phone...
              </Typography>
              <Typography variant="body1" color="text.secondary">
                We’re syncing settings and data. Please ensure your elderly’s phone is awake and connected.
              </Typography>
              <CircularProgress sx={{ mt: 2 }} />
            </Box>
          )}
          {phase === "READY" && (
            <Fade in={phase === "READY"}>
              <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" gap={2}>
                <HeartContainer playAnimation={loveAnimationPlaying}>
                  {loveSent ? (
                    <CheckCircleOutline sx={{ fontSize: "5rem", color: "#48BB78" }} />
                  ) : (
                    <PulsatingHeart />
                  )}
                </HeartContainer>
                <Typography variant="h4" gutterBottom>
                  {loveSent ? "Love sent. How cute!" : "Send your love"}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {loveSent ? "Now, go to the dashboard to get started." : "Tap the button below to send a heart animation to your elderly’s device!"}
                </Typography>
                <Stack direction="row" spacing={2}>
                  <Button variant="contained" color="error" onClick={handleSendLove}>
                    Send your love
                  </Button>
                  {showDashboardButton && (
                    <Button variant="contained" color="success" onClick={handleGoToDashboard}>
                      Go to Dashboard
                    </Button>
                  )}
                </Stack>
              </Box>
            </Fade>
          )}
        </CardContent>
      </Card>
    </Container>
  )
}
export default OnboardingCheckElderly
